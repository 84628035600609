<template>
  <div>
    <div class="footer">
      <div class="footer-inner">
        <div class="footer-content">
          <span class="bigger-120">
            <span class="blue bolder">SistemaWeb.PRO</span>
            Application &copy; 2013-2019 - <small>{{ version }}</small>
          </span>
        </div>
      </div>
    </div>
    <update-popup />
    <back-to-top />
  </div>
</template>

<script>
import BackToTop from './BackToTop'
import UpdatePopup from './UpdatePopup'

export default {
  components: {
    BackToTop,
    UpdatePopup
  },
  data() {
    return {
      version: ''
    }
  },
  mounted() {
    let version = localStorage.getItem('_version')
    this.version = version
  }
}
</script>
